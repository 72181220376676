import React, { useState, useEffect } from 'react';

// Define the range of notes from c2 to b5
const allNotes = [
  'c2', 'd2', 'e2', 'f2', 'g2', 'a2', 'b2',
  'c3', 'd3', 'e3', 'f3', 'g3', 'a3', 'b3',
  'c4', 'd4', 'e4', 'f4', 'g4', 'a4', 'b4',
  'c5', 'd5', 'e5', 'f5', 'g5', 'a5', 'b5'
];

// Create piano keyboard structure
const createPianoKeyboard = () => {
  // Map of white keys
  const whiteKeys = allNotes.map(note => ({
    note,
    isMiddleC: note === 'c4'
  }));
  
  // Map of black keys (sharps/flats)
  const blackKeys = [];
  for (let octave = 2; octave <= 5; octave++) {
    // C# D# F# G# A#
    ['c', 'd', 'f', 'g', 'a'].forEach((noteName, index) => {
      blackKeys.push({
        note: `${noteName}#${octave}`,
        position: index < 2 ? index : index + 1, // Adjust position for gaps at E and B
        octave
      });
    });
  }
  
  return { whiteKeys, blackKeys };
};

export default function MusicNoteApp() {
  const [currentNote, setCurrentNote] = useState('');
  const [selectedClefs, setSelectedClefs] = useState({ treble: true, bass: true });
  const [showMnemonics, setShowMnemonics] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [imageCounter, setImageCounter] = useState(0);
  const [pianoKeyboard] = useState(createPianoKeyboard());

  // Mnemonics for staff lines and spaces
  const mnemonics = {
    treble: {
      lines: "Every Good Boy Does Fine (E G B D F)",
      spaces: "FACE (F A C E)"
    },
    bass: {
      lines: "Good Boys Do Fine Always (G B D F A)",
      spaces: "All Cows Eat Grass (A C E G)"
    }
  };

  // Function to simulate getting a random image file from a folder
  const getRandomNoteImage = () => {
    // Filter available clefs based on user selection
    let availableClefs = [];
    if (selectedClefs.treble) availableClefs.push('treble');
    if (selectedClefs.bass) availableClefs.push('bass');
    
    // Default to treble if nothing selected
    if (availableClefs.length === 0) {
      availableClefs = ['treble'];
      setSelectedClefs({ ...selectedClefs, treble: true });
    }
    
    // Select a random clef and note
    const randomClef = availableClefs[Math.floor(Math.random() * availableClefs.length)];
    const randomNote = allNotes[Math.floor(Math.random() * allNotes.length)];
    
    // Simulate the file naming convention: "clef_note.jpg"
    const fileName = `${randomClef}_${randomNote}`;
    
    return {
      fileName,
      note: randomNote,
      clef: randomClef
    };
  };

  // Initialize with a random note
  useEffect(() => {
    getNextNote();
  }, []);

  const getNextNote = () => {
    const noteImage = getRandomNoteImage();
    setCurrentNote(noteImage.note);
    setSelectedNote(null);
    setIsCorrect(null);
    setImageCounter(prev => prev + 1);
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setIsCorrect(note === currentNote);
  };

  const handleClefChange = (clef) => {
    // Ensure at least one clef is always selected
    const newSelectedClefs = { ...selectedClefs, [clef]: !selectedClefs[clef] };
    if (!newSelectedClefs.treble && !newSelectedClefs.bass) {
      return; // Prevent deselecting both
    }
    setSelectedClefs(newSelectedClefs);
  };

  return (
    <div className="flex flex-col items-center p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6 text-center">
        Music Note Practice
      </h1>
      
      {/* Controls */}
      <div className="flex flex-wrap gap-4 mb-6 justify-center">
        <div className="flex items-center gap-2">
          <span className="font-medium">Clefs:</span>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={selectedClefs.treble}
              onChange={() => handleClefChange('treble')}
              className="mr-1"
            />
            Treble
          </label>
          <label className="inline-flex items-center ml-2">
            <input
              type="checkbox"
              checked={selectedClefs.bass}
              onChange={() => handleClefChange('bass')}
              className="mr-1"
            />
            Bass
          </label>
        </div>
        
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={showMnemonics}
            onChange={() => setShowMnemonics(!showMnemonics)}
            className="mr-1"
          />
          Show Mnemonics
        </label>
        
        <button 
          onClick={getNextNote}
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Next Note
        </button>
      </div>
      
      {/* Mnemonics Display */}
      {showMnemonics && (
        <div className="mb-6 p-4 bg-gray-100 rounded-lg w-full max-w-lg">
          <h3 className="font-bold mb-2">Mnemonics:</h3>
          {selectedClefs.treble && (
            <div className="mb-2">
              <p><strong>Treble Clef:</strong></p>
              <p>Lines: {mnemonics.treble.lines}</p>
              <p>Spaces: {mnemonics.treble.spaces}</p>
            </div>
          )}
          {selectedClefs.bass && (
            <div>
              <p><strong>Bass Clef:</strong></p>
              <p>Lines: {mnemonics.bass.lines}</p>
              <p>Spaces: {mnemonics.bass.spaces}</p>
            </div>
          )}
        </div>
      )}
      
      {/* Music Note Display */}
      <div className="relative mb-6 bg-gray-50 rounded-lg overflow-hidden shadow-md">
        {/* This would be an actual image in a real implementation */}
        <div className="w-72 h-72 flex items-center justify-center">
          {imageCounter > 0 ? (
            <div className="text-center">
              <div className="text-sm mb-2 text-gray-500">
                {currentNote && (
                  <span>
                    {selectedClefs.treble && selectedClefs.bass 
                      ? "Random clef" 
                      : selectedClefs.treble 
                        ? "Treble clef" 
                        : "Bass clef"}
                  </span>
                )}
              </div>
              <div className="w-64 h-64 flex items-center justify-center bg-gray-200 rounded">
                <img 
                  src={`/api/placeholder/300/300`} 
                  alt="Music note"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          ) : (
            <p className="text-gray-500">Click "Next Note" to start</p>
          )}
        </div>
      </div>
      
      {/* Piano Keyboard - Completely Redesigned */}
      <div className="max-w-full w-full mb-6">
        <div className="relative mx-auto" style={{ height: "150px", width: "730px" }}>
          {/* White keys */}
          <div className="flex h-full">
            {pianoKeyboard.whiteKeys.map((key, index) => {
              const isCorrectNote = key.note === currentNote;
              const isSelectedNote = key.note === selectedNote;
              
              let bgColor = 'bg-white';
              
              // Apply color based on selection and correctness
              if (isSelectedNote) {
                bgColor = isCorrect ? 'bg-green-500' : 'bg-red-500';
              } else if (isCorrectNote && selectedNote !== null) {
                bgColor = 'bg-green-500';
              }
              
              return (
                <div
                  key={key.note}
                  className={`relative ${bgColor} border border-gray-800 cursor-pointer`}
                  style={{ width: '26px', height: '100%' }}
                  onClick={() => handleNoteClick(key.note)}
                >
                  {key.isMiddleC && (
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-3 h-3 bg-pink-400 rounded-full"></div>
                  )}
                </div>
              );
            })}
          </div>
          
          {/* Black keys overlay */}
          <div className="absolute top-0 left-0 h-2/3">
            {/* Group 1: C# D# */}
            {[0, 7, 14, 21].map((startIndex) => (
              <React.Fragment key={`group1-${startIndex}`}>
                <div
                  className="absolute bg-black border border-gray-800 cursor-pointer"
                  style={{ 
                    width: '18px', 
                    height: '100%', 
                    left: `${startIndex * 26 + 18}px`,
                    zIndex: 10
                  }}
                  onClick={() => handleNoteClick(`${allNotes[startIndex].charAt(0)}${allNotes[startIndex].slice(1)}`)}
                />
                <div
                  className="absolute bg-black border border-gray-800 cursor-pointer"
                  style={{ 
                    width: '18px', 
                    height: '100%', 
                    left: `${startIndex * 26 + 44}px`,
                    zIndex: 10
                  }}
                  onClick={() => handleNoteClick(`${allNotes[startIndex+1].charAt(0)}${allNotes[startIndex+1].slice(1)}`)}
                />
              </React.Fragment>
            ))}
            
            {/* Group 2: F# G# A# */}
            {[0, 7, 14, 21].map((startIndex) => (
              <React.Fragment key={`group2-${startIndex}`}>
                <div
                  className="absolute bg-black border border-gray-800 cursor-pointer"
                  style={{ 
                    width: '18px', 
                    height: '100%', 
                    left: `${startIndex * 26 + 96}px`,
                    zIndex: 10
                  }}
                  onClick={() => handleNoteClick(`${allNotes[startIndex+3].charAt(0)}${allNotes[startIndex+3].slice(1)}`)}
                />
                <div
                  className="absolute bg-black border border-gray-800 cursor-pointer"
                  style={{ 
                    width: '18px', 
                    height: '100%', 
                    left: `${startIndex * 26 + 122}px`,
                    zIndex: 10
                  }}
                  onClick={() => handleNoteClick(`${allNotes[startIndex+4].charAt(0)}${allNotes[startIndex+4].slice(1)}`)}
                />
                <div
                  className="absolute bg-black border border-gray-800 cursor-pointer"
                  style={{ 
                    width: '18px', 
                    height: '100%', 
                    left: `${startIndex * 26 + 148}px`,
                    zIndex: 10
                  }}
                  onClick={() => handleNoteClick(`${allNotes[startIndex+5].charAt(0)}${allNotes[startIndex+5].slice(1)}`)}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      
      {/* Feedback */}
      {selectedNote && (
        <div className={`mt-4 p-2 rounded ${isCorrect ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {isCorrect 
            ? "Correct!" 
            : `Incorrect. The correct note is ${currentNote}.`}
        </div>
      )}
    </div>
  );
}
